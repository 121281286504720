import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SidebarProvider from './contexts/sidebar/min-sidebar-context';

const Signup = lazy(() => import('./pages/signup/index'));
const Login = lazy(() => import('./pages/login'));
const Main = lazy(() => import('./pages/main/index'));

function App() {
  return (
    <SidebarProvider>
      <div className='app relative min-h-screen'>
        <Suspense fallback={<h1>Loading...</h1>}>
          <Routes>
            <Route path='/' element={<Navigate to='/getstarted/details' />} />
            <Route path='/login' element={<Login />} />
            <Route path='/getstarted/*' element={<Signup />} />
            <Route path='/main/*' element={<Main />} />
          </Routes>
        </Suspense>
      </div>
    </SidebarProvider>
  );
}

export default App;

import React, { createContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as EventTicket } from 'assets/sidenav/tickets.svg';
import { ReactComponent as Home } from 'assets/sidenav/home.svg';
import { ReactComponent as AellaPay } from 'assets/sidenav/loan.svg';
import { ReactComponent as Settings } from 'assets/sidenav/settings.svg';

export const SidebarContext = createContext(null);

const SidebarProvider = ({ children }) => {
  let location = useLocation();
  const [showSidebar, setShowSidebar] = React.useState(true);
  // eslint-disable-next-line
  const [appLinks, setAppLinks] = React.useState(links);

  useEffect(() => {
    if (
      location.pathname.includes('events') ||
      location.pathname.includes('settings') ||
      location.pathname.includes('aella-pay')
    ) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }, [location.pathname]);

  return (
    <SidebarContext.Provider value={{ showSidebar, appLinks }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;

export const useSidebarProvider = () => {
  return React.useContext(SidebarContext);
};

export const links = [
  {
    title: 'Home',
    url: 'home',
    Icon: Home,
    subroutes: null,
  },
  {
    title: 'Aella Pay',
    url: 'aella-pay',
    Icon: AellaPay,
    subroutes: [
      {
        title: 'Customer',
        url: 'aella-pay/customer',
      },
      {
        title: 'Transactions',
        url: 'aella-pay/transactions',
      },
      {
        title: 'Wallet',
        url: 'aella-pay/wallet',
      },
      {
        title: 'API',
        url: 'aella-pay/api',
      },
    ],
  },
  {
    title: 'Event',
    url: 'events',
    Icon: EventTicket,
    subroutes: [
      // {
      //   title: 'Dashboard',
      //   url: 'events',
      // },
      {
        title: 'My Events',
        url: 'events/myevents',
      },
      {
        title: 'Check-in',
        url: 'events/check-in',
      },
    ],
  },
  {
    title: 'Settings',
    url: 'settings',
    Icon: Settings,
    subroutes: [
      {
        title: 'You',
        url: 'settings/dashboard',
      },
      {
        title: 'Them',
        url: 'settings/my-events',
      },
      {
        title: 'ME',
        url: 'settings/check-in',
      },
    ],
  },
];
